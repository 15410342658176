import { createAction, props } from '@ngrx/store';
import { INotification } from '@wam/shared';

export const loadNotifications = createAction('[Application Initializer] Load Notifications');

export const loadNotificationsSuccess = createAction(
  '[Messages and Notifications API] Load Notifications Success',
  props<{ notifications: INotification[] }>(),
);

export const loadNotificationsFail = createAction(
  '[Messages and Notifications API] Load Notifications Fail',
  props<{ error: string }>(),
);

export const markNotificationAsRead = createAction(
  '[Notification Component] Mark Notification as Read',
  props<{ notification: INotification }>(),
);

export const markNotificationAsReadSuccess = createAction(
  '[Feed API] Mark Notification as Read Success',
  props<{ notification: INotification }>(),
);

export const markNotificationAsReadFailed = createAction(
  '[Feed API] Mark Notification as Read Failed',
  props<{ notification: INotification }>(),
);
